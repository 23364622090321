//
// TelefonSeelsorge
// --------------------------------------------------

.row-lila {
    background-color: @ts-lila;
    color: @white;
}

.span-lila {
    background-color: @ts-lila;
}

// linke Seitenleiste
.left-sidebar {
  background-color: @white;
}

// Navigation
.collapsed {
    background-image: url(../../images/triangle.png);
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position: 0px 15px;
}

//.expanded {
//    background-image: url(../../images/triangle-down.png);
//    background-size: 15px 15px;
//    background-repeat: no-repeat;
//    background-position: 0px 15px;
//}
.caret {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 0px;
  border: 0px solid transparent;
  vertical-align: middle;
    background-image: url(../../images/triangle-down.png);
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position: 0px 0px;
}
.navbar-nav:not(.sm-collapsible) ul .caret {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 0px;
  border: 0px solid transparent !important;
  vertical-align: middle;
    background-image: url(../../images/triangle.png);
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position: 0px 0px;
}
span.caret.navbar-toggle.sub-arrow {
  background-image: none;
}
ul.navbar-nav .collapsed {
    background-image: none;
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position: 0px 15px;
}

ul.navbar-nav .expanded {
    background-image: none;
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position: 0px 15px;
}

;ul.menu.primary {
;  font-size: @font-size-large;
;}
;ul.menu li {
;  padding-left: 10px;
;}
;ul.menu li a {
;  margin-left: -10px;
;  padding-left: 20px;
;}
.nav a {
  color: @ts-lila;
}
.nav > li.active > a,
.nav > li.active > a:hover {
  font-weight: bold;
//  background-color: @ts-orange;
}
.nav-pills a {
  color: @gray-light;
}
.nav-pills > li.active > a {
  background-color: transparent;
  color: @gray-light
}
.nav-pills > li.active > a:hover {
  background-color: lighten(@ts-orange, 20%);
}
ul.dropdown-menu {
  background-color: @white;
  padding-left: 0px !important;
}

ul.menu ul {
  border-left: 1px solid @ts-lila;
  border-right: 1px solid @ts-lila;
}
.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  background-color: lighten(@ts-orange, 20%);
}
ul.menu li a:hover {
  background-color: lighten(@ts-orange, 20%);
  background-image: inherit;
  background-repeat: inherit;
  background-position: inherit;
}
.nav > li > a:focus {
  background-color: inherit;
}

.nav .nav-header {
  color: @ts-lila;
}

.book-toc>.dropdown-menu .dropdown-menu {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    display: block;
    font-size: 12px;
    margin: 0;
    padding: 0;
    position: static;
    width:100%;
}

.book-toc>.dropdown-menu .dropdown-menu>li {
    padding-left: 20px;
}

.book-toc>.dropdown-menu .dropdown-menu>li>a {
    margin-left: -20px;
    padding-left: 40px;
}

.pagination li.active a {
  background-color: @white;
  color: @ts-lila;
  font-weight: bold;
}

.pagination li a {
  color: @ts-lila;
  border: 0px solid @white;
  font-size: 20px;
}

.node-readmore {
  margin-top: 10px;
}

.node-readmore a {
  padding: 4px 15px 3px 15px;
  background-color: @ts-orange;
  color: @white;
}

// Logo
.logo {
  padding-top: 30px;
}

// Überschriften
.page-header {
    border-bottom-color: @ts-orange;
}
.node-teaser h2 a {
  color: @ts-lila;
}

// Fußzeile
.footer {
    margin-top: 0px !important;
    padding: 12px 0 24px;
    border-top: 1px solid @ts-olive !important;
}

.yellow-box {
    text-align: left;
    border: 5px solid @white;
    background-color: lighten(@ts-orange, 33%);
    padding: 10px 20px 8px 20px;
    font-weight: bold;
    margin: 10px;
    color: @ts-lila;
}

.yellow-box p {
    padding: 3px 0px 3px 0px;
    margin: 0px;
}

.yellow-box ul {
    padding: 0px;
    margin: 0px;
    margin-left: 20px;
}

.yellow-box a {
    color: @ts-lila;
}

@media (max-width: 767px) { 
    .btn-default {
        //border: 5px solid @white;
        background-color: @ts-orange;
        //padding: 10px 20px 8px 20px;
        //font-weight: bold;
        //margin: 10px;
        color: @ts-lila;
    }
    .navbar-default {
        text-align: center;
    }
    .navbar-nav li a {
        padding-right: 50px;
    }
    .navbar-nav .dropdown-menu li a {
        padding-right: 50px !important;
    }
    ul.dropdown-menu {
        margin-right: 0px !important;
        background-color: @gray-lighter !important;
        border-left: 3px solid @ts-orange !important;
    }
    ul.dropdown-menu li a {
        text-align: center;
    }
}


.triangle {
    list-style-image: url(../../images/triangle.png);
}

a.triangles:before {
    margin-left: -10px;
    content:"» ";
    color: @ts-orange;
}